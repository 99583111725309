.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.categories {
  display: flex;
  flex-direction: column;
  width: 100%;
}