.container {
    position: relative;
    display: flex;
    align-items: center;
    width: 160px;
    top: 0;
    left: 0;
    margin-right: 32px;
    color: var(--chakra-colors-dark-800);
    font-weight: var(--chakra-fontWeights-black);
    font-family: var(--chakra-fonts-heading);
    font-size: var(--chakra-fontSizes-xl);
 
    > svg {
        height: 100%;
        max-height: 32px;
        width: auto;
    }
}