@use "sass:map";
@import 'helpers';

$sidebarColors: (
  blue: $primary-400,
  brightBlue: $secondary-600,
  red: $alerts-error,
  green: $alerts-success,
  yellow: $alerts-warning,
  orange: $orange,
  purple: $violet,
);

.category {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: rem(14px) rem(20px) rem(14px) 0;

  &:hover {
    .title {
      color: $dark-900;
    }

    .icon {
      @each $colorName , $colorValue in $sidebarColors {
        &-color-#{$colorName} {
          background: linear-gradient(to bottom, $border, $colorValue);
          box-shadow: 0 2px 6px 0 color-rgba($colorValue, .4);
        }
      }
    }
  }

  &.active {
    .title {
      color: $primary-500;
      font-weight: 700;
    }

    .icon {
      @each $colorName , $colorValue in $sidebarColors {
        &-color-#{$colorName} {
          background: linear-gradient(to bottom, $border, $colorValue);
          box-shadow: 0 2px 6px 0 color-rgba($colorValue, .4);
        }
      }
    }
  }

  .title {
    color: color-rgba($dark-900, .8);    
    font-weight: 600;
    letter-spacing: 0.2px;
    transition: all .1s;
  }

  .icon {
    @include size(rem(32px));

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    margin-right: rem(12px);
    background: linear-gradient(to bottom, $border, $border);
    border-radius: rem(8px);
    color: $dark-600;
    transition: all .1s;
    overflow: hidden;
  
    @each $colorName , $colorValue in $sidebarColors {
      &-color-#{$colorName} {
        color: $colorValue;
      }
    }

    .iconContent {
      @include size(100%);

      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: $dark-50;
      border-radius: rem(7px);

      > svg {
        @include size(rem(20px));
      }
    }
  }

}



